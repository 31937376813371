import React from "react";
import Base from "../components/Base";
import ReviewBlock from "../components/ReviewBlock";

const reviews = () => {
    return (
            <Base title={'Reviews'} description={'Come see what our customers have to say about us! We are operating out of Harrow and surrounding areas, with over 10 years of experience specializing in domestic and commercial installations.'}>
                <h2 className={'colouredTitle'}>Reviews</h2>
                <hr/>
                <p>Below are some reviews and testimonials from our customers, feel free to view more on <b><a className={'specialLink'} href="https://g.page/jack-o-connor-electrician?share">Google</a></b> and add your own review!</p>
                <ReviewBlock
                    text={`Helped installed my smart home lighting including advising me on the options available with consideration to price and features. 
                        Along with sorting out any issues that arised during installation, Overall extremely happy and would recommend him to friends.`}
                    from={'Jason (Northolt)'}
                    stars={5}
                />
                <ReviewBlock
                    text={`Execellent service! Installed outdoor lightning, helped sort out a short caused by my dish washer couldn't recommend him enough!`}
                    from={'Helen (Harrow)'}
                    stars={5}
                />
            </Base>
        );
}
export default reviews;
