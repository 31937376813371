import React from "react";

export default class ReviewBlock extends React.Component{
    renderStars(numberOfStars){
        const stars = [];
        for(let i = 0; i < 5; i++){
            if(i < numberOfStars){
                stars.push(<span class="fas fa-star checked"/>);
            }else{
                stars.push(<span class="fa fa-star"/>);
            }            
        }
        return stars;
    }

    render(){
        return (
            <div className='reviewBlock'>
                    <i>{this.props.text}</i>
                    <div className='from'>
                        <p>- {this.props.from}</p>
                        <div className='stars'>
                            {this.renderStars(this.props.stars)}
                        </div>                        
                    </div>
                </div>
        );
    }
}